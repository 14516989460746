'use client'
import clsx from 'clsx'

import { Typo } from '~/components/typo'
import { Link, usePathname } from '~/i18n/routing'
import { type InternalLink } from '~/lib/navigation'

export interface NavItemProps {
  name: string
  href: InternalLink
  typoProps?: React.ComponentProps<typeof Typo>
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const NavItem: React.FC<NavItemProps> = ({
  name,
  href,
  typoProps,
  className,
  onClick,
}) => {
  const pathname = usePathname()

  return (
    <Link
      href={href}
      className={clsx(
        'w-fit underline-offset-4 hover:opacity-60',
        pathname === href ? 'underline' : '',
        className,
      )}
      aria-current={pathname === href ? 'page' : undefined}
      onClick={onClick}
    >
      <Typo as="span" {...typoProps}>
        {name}
      </Typo>
    </Link>
  )
}
