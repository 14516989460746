import { createNavigation } from 'next-intl/navigation'
import { defineRouting } from 'next-intl/routing'

export type ValidLocale = (typeof routing.locales)[number]

export const routing = defineRouting({
  locales: ['en', 'ja'],
  defaultLocale: 'ja',
  // localePrefix: 'as-needed',
  // avoid automatic redirects for better SEO
  // localeDetection: false,
})

export const { Link, redirect, usePathname, useRouter } =
  createNavigation(routing)
