'use client'

import clsx from 'clsx'

import { usePathname } from '~/i18n/routing'
import { internalLink } from '~/lib/navigation'

interface Props extends React.ComponentProps<'div'> {}

export const HeaderInner: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const pathname = usePathname()
  const isSecondaryColor = pathname === internalLink.index

  return (
    <div
      className={clsx('flex items-center', {
        'text-white/90': isSecondaryColor,
        className,
      })}
      {...props}
    >
      {children}
    </div>
  )
}
